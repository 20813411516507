export const widths = {
  full: 224,
  mini: 64,
  button: 48,
};

export const heights = {
  menuitem: 48,
  toolbar: 56,
  button: 48,
};
