const lime = {
  50: "#f9fbe7",
  200: "#e6ee9c",
  100: "#f0f4c3",
  300: "#dce775",
  400: "#d4e157",
  500: "#cddc39",
  600: "#c0ca33",
  700: "#afb42b",
  800: "#9e9d24",
  900: "#827717",
};

export default lime;
