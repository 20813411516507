const colors = {
  300: "#9189AC",
  400: "#726894",
  500: "#53477D",
  600: "#443B67",
  700: "#352E50",
  800: "#312A49",
};

export default colors;
