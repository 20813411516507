const teal = {
  200: "#00fafa",
  300: "#00c8c8",
  400: "#00a0a0",
  500: "#008080",
  600: "#006666",
  700: "#005252",
};

export default teal;
