const blue = {
  50: "#e3f2fd",
  100: "#bbdefb",
  200: "#64b5f6",
  300: "#64b5f6",
  400: "#42a5f5",
  500: "#2196f3",
  600: "#1e88e5",
  700: "#1976d2",
  800: "#1565c0",
  900: "#0d47a1",
};

export default blue;
