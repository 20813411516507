import React from "/vendor/react";
import { SvgIcon } from "/vendor/@material-ui/core";
import Bg from "./Bg";

interface Props {
  color: string;
}

const Icon = React.memo(({ color, ...props }: Props) => {
  return (
    <SvgIcon viewBox="0 0 64 64" {...props}>
      <Bg color={color}>
        <path
          d="M32.2 79c11.5 0 25.4-6 25.4-17 0-10.8-13.9-22.3-25.4-22.3-11.5 0-25.8 11.5-25.8 22.4C6.4 72.9 20.7 79 32.2 79z"
          fill="#793D2E"
        />
        <path
          d="M32.2 79c11.5 0 25.4-5.7 25.4-15.9 0-10.1-14-17.3-25.5-17.3S6.4 53 6.4 63.1C6.4 73.3 20.7 79 32.2 79z"
          fill="#414141"
        />
        <g transform="translate(2 9)" fill="#AF5944">
          <ellipse cx="30.1" cy="26.4" rx="22.7" ry="17.8" />
          <path d="M7.9 4.3c1.8-.2 3.9.4 6.3 1.7 2.3 1.4 5.1 3.6 8.3 6.6L8.9 21.1 8 10.6c-.2-2.6-.2-4.7 0-6.3zM52.5 4.3c-1.7-.2-3.8.4-6.2 1.7a44.1 44.1 0 00-8.3 6.6l13.5 8.5c.5-4.4.9-7.9 1-10.5.2-2.6.2-4.7 0-6.3z" />
        </g>
        <g transform="translate(7.5 15.1)">
          <ellipse fill="#EFEFEF" cx="24.6" cy="28.6" rx="11.7" ry="6.1" />
          <path
            d="M29.6 7.8c1.5-.2 4.3 2.3 4.4 3 .1.6-1 1.4-2.5 1.7-1.4.2-2.3.7-3.4-1a2.4 2.4 0 011.5-3.7zM19.7 7.8c-1.5-.2-4.3 2.3-4.4 3-.2.6 1 1.4 2.4 1.7 1.5.2 2.3.7 3.4-1 1.1-1.6 0-3.4-1.4-3.7z"
            fill="#EFEFEF"
          />
          <ellipse fill="#000" cx="16" cy="15.7" rx="1.8" ry="3.1" />
          <ellipse fill="#000" cx="33.2" cy="15.7" rx="1.8" ry="3.1" />
          <path
            d="M24.6 28.4c3.7 0 11.7 3.3 11.7-.1 0-3.5-8-10.6-11.7-10.6C21 17.7 13 24.8 13 28.2c0 3.5 8 .2 11.6.2z"
            fill="#EFEFEF"
          />
          <ellipse fill="#414141" cx="24.6" cy="23" rx="4.3" ry="1.8" />
          <path
            d="M45.5 1.6l-.9 10L38.2 8a33 33 0 014-4.5c1.4-1.2 2.5-1.8 3.3-1.9zM3.7 1.6l.9 10L11 8a33 33 0 00-4-4.5C5.5 2.3 4.4 1.7 3.6 1.6z"
            fill="#414141"
          />
          <path fill="#414141" fillRule="nonzero" d="M25.9 24.3h-2.5v4.9H26z" />
          <path
            fill="#414141"
            fillRule="nonzero"
            d="M24.6 26.2L23 28l4.2 4.2 1.7-1.7z"
          />
          <path
            fill="#414141"
            fillRule="nonzero"
            d="M24.6 26.2l1.8 1.7-4.2 4.2-1.8-1.7z"
          />
        </g>
        <g fill="#EFEFEF">
          <path d="M48.4 44.4c-5.3 5.2.7-5.5-2.2-7.3-2.8-1.8-2.3-3.6.5-5.6 1.8-1.4 3-.5 4.8.5.9.5 1.1 2.7 1.3 3.8.1.9.5 1.6 0 3-.2.6-.4 1.4-1.3 2.5-2.7 3.2-.4.3-3.1 3z" />
        </g>
        <g fill="#EFEFEF">
          <path d="M15.6 44.4c5.4 5.2-.7-5.5 2.2-7.3 2.8-1.8 2.3-3.6-.5-5.6-1.8-1.4-3-.5-4.8.5-.9.5-1.1 2.7-1.3 3.8-.1.9-.5 1.6 0 3 .2.6.4 1.4 1.3 2.5 2.7 3.2.4.3 3.1 3z" />
        </g>
      </Bg>
    </SvgIcon>
  );
});
Icon.displayName = "Avatar.Lion";

export default Icon;
