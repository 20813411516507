import React from "/vendor/react";
import { SvgIcon } from "/vendor/@material-ui/core";
import Bg from "./Bg";

interface Props {
  color: string;
}

const Icon = React.memo(({ color, ...props }: Props) => {
  return (
    <SvgIcon viewBox="0 0 64 64" {...props}>
      <Bg color={color}>
        <g transform="translate(10.7 41)">
          <ellipse fill="#916C47" cx="20.5" cy="25" rx="20.2" ry="25" />
          <ellipse fill="#EFEFEF" cx="20.5" cy="23.4" rx="10.6" ry="11.7" />
        </g>
        <g fill="#AC8157">
          <path d="M30.6 61.3c-1-.1-1.7-.6-2-1.3-1.2-2.4-1.9-6.3-2.4-7.7-1.6-4.6-4.3-6.8-5.1-9-.5-1.3-1-5.3-1.2-6.8-.8-7-.9-10-.2-9 1.6-1 2.8-1.5 3.5-1.6 4.4-.8 6.3-.8 8.8-.8 2.2 0-1.6 8.1 1.7 8.6 1 .2 1 4 0 11.6 2.5-1.7 2.5.6 0 7-1.4 3.5 1.5 5.3-1 9-.1.3-.8.3-2.1 0z" />
          <path d="M32.8 61.3c1-.1 1.7-.6 2-1.3 1.1-2.4 1.9-6.3 2.3-7.7 1.7-4.6 4.3-6.8 5.2-9 .5-1.3 1-5.3 1.1-6.8.9-7 1-10 .2-9-1.6-1-2.7-1.5-3.5-1.6-4.3-.8-6.2-.8-8.8-.8-2.1 0 1.6 8.1-1.6 8.6-1 .2-1 4 0 11.6-2.6-1.7-2.6.6 0 7 1.3 3.5-1.5 5.3.9 9 .2.3.9.3 2.2 0z" />
        </g>
        <g fill="#AC8157">
          <path d="M13.4 37c-1.7-.4-3-1-3.7-1.8-.7-.8-1.4-2.3-2-4.6L22 26.5c1.2 1 1.2 2.3 0 4.1-1 1.6-1.2 4.3-4.8 6.4-.3.2-1.5.2-3.7 0zM49.9 37c1.7-.4 3-1 3.7-1.8.8-.8 1.4-2.3 2-4.6l-14.2-4.1c-1.2 1-1.2 2.3 0 4.1 1 1.6 1.2 4.3 4.8 6.4.3.2 1.5.2 3.7 0z" />
        </g>
        <g transform="translate(8.6 32.5)">
          <ellipse
            fill="#414141"
            transform="rotate(-18 17.5 9.3)"
            cx="17.5"
            cy="9.3"
            rx="1.6"
            ry="2.7"
          />
          <ellipse
            fill="#414141"
            transform="scale(-1 1) rotate(-18 0 187.2)"
            cx="28.2"
            cy="9.3"
            rx="1.6"
            ry="2.7"
          />
          <path
            d="M24.7 27.4c-.2 1-.7 1.4-1.3 1.4-.6 0-1.2-.5-1.6-1.4h3z"
            stroke="#414141"
            fill="#414141"
            strokeLinecap="square"
          />
          <path
            d="M40.3 3.9c2.6 0 5.1-2.7 5.1-3.6 0-.9-2.9 1.4-5.5 1.4-2.7 0-4.1-.6-4.1.3 0 .9 1.8 1.9 4.5 1.9zM5.9 3.9C3.2 3.9.7 1.2.7.3c0-.9 2.9 1.4 5.5 1.4 2.7 0 4.1-.6 4.1.3 0 .9-1.8 1.9-4.4 1.9z"
            fill="#EFEFEF"
          />
        </g>
        <g fill="#DDB56A">
          <path d="M38.3 28.9c.9-3.1 1.7-5.1 2.4-6l3.3-1.7 4 1.6h3.9l3.1-1.6 2-2V16l-1-2.8V16l-1 2.6-2 1.8-3 .6-3.3-.6-2-1.1 2-2.1h2.4l1-1.2.7-2.8-1.7 1.6h-1.5v-1.6l.4-2.4L46.7 9 44 7.3l1.7 3.5v4L44 17.2c-2-1.9-3-2.7-3.3-2.4-.2.2-4.2.4-4 1.2.2.4 2.2.2 4 1.2.4.2 1 1 1.5 2L39.4 21l-2.3 1.8-1.1 2-.8 3.1.8 2 1 .7c.6.3.8.2 1.3-1.7zM25 28.9c-.8-3.1-1.5-5.1-2.3-6l-3.2-1.7-4 1.6h-4l-3-1.6-2-2V16l1-2.8V16l1 2.6 2 1.8 2.9.6 3.3-.6 2-1.1-2-2.1h-2.4l-1-1.2-.6-2.8 1.6 1.6h1.5v-1.6l-.3-2.4L16.7 9l2.8-1.8-1.8 3.5v4l1.8 2.4c1.9-1.9 3-2.7 3.2-2.4.2.2 4.2.4 4 1.2-.2.4-2.2.2-4 1.2-.4.2-1 1-1.5 2L24 21l2.4 1.8 1 2 .8 3.1-.8 2-1 .7c-.5.3-.8.2-1.3-1.7z" />
        </g>
      </Bg>
    </SvgIcon>
  );
});
Icon.displayName = "Avatar.Deer";

export default Icon;
