import React from "/vendor/react";
import createSvgIcon from "./createSvgIcon";

const Icon = createSvgIcon(
    <g fill="currentColor" transform="translate(6 3)">
      <path d="M10.6 10.6L12 12l-6 6-6-6 1.4-1.4L6 15.2l4.6-4.6zM6 0l6 6-1.4 1.4L6 2.8 1.4 7.4 0 6l6-6z" />
    </g>,
    "SelectIcon",
);

export default Icon;
